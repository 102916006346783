<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Tipo <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.tipo"
            :options="form_options.tipo"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
      >
        <template #label>
          Encargos Sociais <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-input-group append="%">
            <KTInputNumeric
              v-model="form.encargos_sociais"
              :precision="2"
              :min="0"
              :max="100"
              placeholder="Digite o percentual de encargos sociais"
              autocomplete="off"
              :required="!finalizado"
              :disabled="finalizado"
            />
          </b-input-group>
        </template>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipEncargoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_mao_de_obra_encargo: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-percentage",
        title: "Encargos Sociais e Trabalhistas",
        description: `Os encargos sociais e trabalhistas são tributos que, por
          lei, as empresas precisam pagar em benefício de seus funcionários.
          Cadastre o valor percentual dos encargos de acordo com o tipo de empresa
          que está analisando. Recomendamos consultar um contador para definir corretamente.`
      },
      form: {},
      form_options: {
        tipo: [
          { value: 1, text: "Direta" },
          { value: 2, text: "Indireta" },
          { value: 3, text: "Administrativa" },
          { value: 4, text: "Diaristas" },
          { value: 5, text: "Outros" }
        ]
      },
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/encargo`;
    },
    manual() {
      return {
        session: "encargo",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_mao_de_obra_encargo;
    },
    parentRoute() {
      return {
        name: "pip_encargo_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_mao_de_obra_encargo)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].encargo ?? {};
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmit(this.idpip_mao_de_obra_encargo, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
